<template>
  <v-container class="px-md-10"
               v-show="user_profile_delete_account_tab_view_model.initial_load_is_complete"
  >
    <div v-if="!user_profile_delete_account_tab_view_model.want_to_delete"
         class="container-criterion mx-auto"
    >
      <h3 class="mx-auto text-center mb-10">
        {{ user_profile_delete_account_tab_view_model.translate('card_1.title_1') }}<br>
        {{ user_profile_delete_account_tab_view_model.translate('card_1.title_2') }}
      </h3>
      <p class="mx-auto text-center primary--text text-text">
        {{ user_profile_delete_account_tab_view_model.translate(
        `${user_profile_delete_account_tab_view_model.label_subtitle_translation}_1`) }}<br>
        {{ user_profile_delete_account_tab_view_model.translate(
        `${user_profile_delete_account_tab_view_model.label_subtitle_translation}_2`) }}<br>
        {{ user_profile_delete_account_tab_view_model.translate(
        `${user_profile_delete_account_tab_view_model.label_subtitle_translation}_3`) }}<br>
      </p>
      <div class="d-flex flex-column">
        <v-col cols="12" class="mx-3 mx-sm-7">
          <ul class="px-0">
            <li class="d-flex text-left"
                v-if="user_profile_delete_account_tab_view_model.customer_has_wealth_account"
            >
              <h6>
                {{ user_profile_delete_account_tab_view_model.translate('criterion_1.title') }}
              </h6>
              <label class="mx-4">
                <custom-tooltip
                  :small-tooltip="true"
                  color="accent"
                  :label="user_profile_delete_account_tab_view_model
                  .translate('criterion_1.tooltip')"
                />
              </label>
            </li>
            <li class="d-flex text-left"
                v-if="user_profile_delete_account_tab_view_model.customer_has_wealth_account"
            >
              <v-icon :color="user_profile_delete_account_tab_view_model.color.icon_1">
                mdi-check-circle
              </v-icon>
              <span class="text-font-secondary px-2"
                    :class="user_profile_delete_account_tab_view_model.color.text_1">
             {{ user_profile_delete_account_tab_view_model.translate('criterion_1.description_1') }}
                <br>
             {{ user_profile_delete_account_tab_view_model.translate('criterion_1.description_2') }}
                <br>
              </span>
            </li>
            <li class="d-flex text-left"
                v-if="user_profile_delete_account_tab_view_model.show_total_withdrawal_link"
            >
              <label class="black--text pt-5">
                {{ user_profile_delete_account_tab_view_model.translate('criterion_1.label_1') }}
                <v-btn class="sky-blue--text px-0 m-0" plain
               @click="user_profile_delete_account_tab_view_model.showFlowWithdrawalTotalWealth">
                  <label class="text-decoration-underline accent--text cursor-pointer">
                    {{
                      user_profile_delete_account_tab_view_model.translate('criterion_1.label_2')
                    }}
                  </label>
                </v-btn>
              </label>
            </li>
            <li class="d-flex text-left pt-5"
                v-if="user_profile_delete_account_tab_view_model.customer_has_pocket_account"
            >
              <h6>
                {{ user_profile_delete_account_tab_view_model.translate('criterion_2.title') }}
              </h6>
              <label class="mx-4">
                <custom-tooltip
                  :small-tooltip="true"
                  color="accent"
                  :label="user_profile_delete_account_tab_view_model
                  .translate('criterion_2.tooltip')"
                />
              </label>
            </li>
            <li class="d-flex text-left"
                v-if="user_profile_delete_account_tab_view_model.customer_has_pocket_account"
            >
              <v-icon :color="user_profile_delete_account_tab_view_model.color.icon_2">
                mdi-check-circle
              </v-icon>
              <span class="text-font-secondary px-2"
                    :class="user_profile_delete_account_tab_view_model.color.text_2">
             {{ user_profile_delete_account_tab_view_model.translate('criterion_2.description_1') }}
                <br>
             {{ user_profile_delete_account_tab_view_model.translate('criterion_2.description_2') }}
                <br>
              </span>
            </li>
            <li class="d-flex text-left"
                v-if="user_profile_delete_account_tab_view_model.customer_has_pocket_account"
            >
              <label class="black--text pt-5">
                {{ user_profile_delete_account_tab_view_model.translate('criterion_2.label_1') }}
                <v-btn class="sky-blue--text px-0 m-0" plain
                       :href="user_profile_delete_account_tab_view_model.pocket_portal_url"
                       target="_blank"
                >
                  <label class="text-decoration-underline accent--text cursor-pointer">
                    {{
                      user_profile_delete_account_tab_view_model.translate('criterion_2.label_2')
                    }}
                  </label>
                </v-btn>
              </label>
            </li>
          </ul>
        </v-col>
      </div>
      <div class="d-flex justify-center pt-5">
        <v-btn
          color="accent"
          width="80%"
          x-large
          rounded
          class="text-body"
          :disabled="user_profile_delete_account_tab_view_model.button_continue_is_disabled"
          :loading="user_profile_delete_account_tab_view_model.is_loading"
          @click="user_profile_delete_account_tab_view_model.nextStep">
          {{ user_profile_delete_account_tab_view_model.translate('card_1.continue') }}
        </v-btn>
      </div>
    </div>
    <div v-else>
      <p class="text-h5 font-weight-medium mx-auto text-center primary--text mb-10">
        {{ user_profile_delete_account_tab_view_model.translate('card_2.title_1') }}<br>
        {{ user_profile_delete_account_tab_view_model.translate('card_2.title_2') }}
      </p>
      <p class="mx-auto text-center primary--text text-text mb-10">
        {{ user_profile_delete_account_tab_view_model.translate('card_2.description_1') }}<br>
        {{ user_profile_delete_account_tab_view_model.translate('card_2.description_2') }}<br>
        {{ user_profile_delete_account_tab_view_model.translate('card_2.description_3') }}<br>
        {{ user_profile_delete_account_tab_view_model.translate('card_2.description_4') }}
      </p>
      <label class="inactive-blue--text">
        {{ user_profile_delete_account_tab_view_model.translate('card_2.password') }}
      </label>
      <v-text-field
        name="current_password"
        v-model="user_profile_delete_account_tab_view_model.inputs.password"
        prepend-inner-icon="mdi-lock"
        class="pt-0"
        :type="user_profile_delete_account_tab_view_model.rules.password.type"
        :append-icon="user_profile_delete_account_tab_view_model.rules.password.type === 'text'
        ? 'mdi-eye'
        : 'mdi-eye-off'"
        @click:append="user_profile_delete_account_tab_view_model.rules.password.type === 'text'
        ? user_profile_delete_account_tab_view_model.rules.password.type = 'password'
        : user_profile_delete_account_tab_view_model.rules.password.type = 'text'"
        :rules="user_profile_delete_account_tab_view_model.rules.password.rules"
      />
      <div class="d-flex justify-center pt-16">
        <v-btn
          color="accent"
          width="80%"
          x-large
          rounded
          class="text-body"
          :disabled="user_profile_delete_account_tab_view_model.button_delete_account_is_disabled"
          :loading="user_profile_delete_account_tab_view_model.is_loading"
          @click="user_profile_delete_account_tab_view_model.deleteAccount">
        {{ user_profile_delete_account_tab_view_model.translate('card_2.delete_account') }}
        </v-btn>
      </div>
    </div>
    <user-profile-delete-account-dialog
     :is-open.sync="user_profile_delete_account_tab_view_model
     .show_user_profile_delete_account_dialog"/>
    <delete-account-dialog
      :is-open.sync="user_profile_delete_account_tab_view_model
      .flow_withdrawal_total_wealth_is_open"/>
  </v-container>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import UserProfileDeleteAccountTabViewModel
  from '@/vue-app/view-models/components/user-profile/user-profile-tabs/user-profile-delete-account-tab-view-model';

@Component({
  name: 'UserProfileDeleteAccountTab',
  components: {
    UserProfileDeleteAccountDialog: () => import('@/vue-app/components/user-profile/user-profile-tabs/UserProfileDeleteAccountDialog.vue'),
    DeleteAccountDialog: () => import('@/vue-app/components/user-profile/user-profile-tabs/user-profile-delete-account/DeleteAccountDialog.vue'),
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class UserProfileDeleteAccountTab extends Vue {
  user_profile_delete_account_tab_view_model = Vue.observable(
    new UserProfileDeleteAccountTabViewModel(),
  );

  created() {
    this.user_profile_delete_account_tab_view_model.initialize();
  }
}
</script>

<style scoped>
  .container-criterion {
    max-width: 327px;
  }
</style>
