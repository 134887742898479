import TYPES from '@/types';
import { requiredRule } from '@/vue-app/utils/form-rules';

// Application
import {
  SearchAllianzAccountReportQuery,
} from '@/modules/my-investment/allianz/allianz-account-report/application/queries';
import GetCustomerContractedInvestmentProductsQuery from '@/modules/flagship/customer-contracted-investment-products/application/queries/get-customer-contracted-investment-products-query';
import CancelAccountCommand
  from '@/modules/user/cancel-account/application/commands/cancel-account-command';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class UserProfileDeleteAccountTabViewModel {
  @Inject(TYPES.GET_CUSTOMER_CONTRACTED_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_contracted_products_query!: GetCustomerContractedInvestmentProductsQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_REPORT_QUERY)
  private readonly search_allianz_account_report_query!: SearchAllianzAccountReportQuery;

  @Inject(TYPES.CANCEL_ACCOUNT_COMMAND)
  private readonly cancel_account_command!: CancelAccountCommand;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  private readonly error_allianz_account_report_not_exits = 'allianz_account_report_by_customer_id_list:[], not exists';

  private readonly customer_id = sessionStorage.getItem('user_id');

  readonly i18n_namespace = 'components.user-profile.user-profile-tabs.user-profile-delete-account-tab';

  readonly pocket_portal_url = process.env.VUE_APP_KUSPIT_PORTAL_URL;

  is_loading = false;

  initial_load_is_complete = false;

  want_to_delete = false;

  show_user_profile_delete_account_dialog = false;

  flow_withdrawal_total_wealth_is_open = false;

  flow_withdrawal_total_pocket_is_open = false;

  customer_has_pocket_account = false;

  customer_has_wealth_account = false;

  wealth_balance_is_equal_to_zero = false;

  label_subtitle_translation = 'card_1.description_both_products';

  label_criterion_translation = 'card_1.description_3_plural';

  inputs = {
    password: '',
  };

  rules = {
    password: {
      type: 'password',
      rules: [requiredRule],
    },
  };

  color = {
    icon_1: 'dark-grey',
    text_1: 'dark-grey--text',
    icon_2: 'sky-blue',
    text_2: 'sky-blue--text',
  };

  get button_continue_is_disabled() {
    return !this.wealth_balance_is_equal_to_zero || this.is_loading;
  }

  get button_delete_account_is_disabled() {
    return !this.inputs.password || this.is_loading;
  }

  get show_total_withdrawal_link() {
    return this.customer_has_wealth_account && !this.wealth_balance_is_equal_to_zero;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  setActiveColorsWealthSection = () => {
    this.color.icon_1 = 'sky-blue';
    this.color.text_1 = 'sky-blue--text';
  }

  setLabelCriterionTranslation = () => {
    if (!this.customer_has_wealth_account || !this.customer_has_pocket_account) {
      this.label_criterion_translation = 'card_1.description_3_singular';
    }
  }

  setSubtitleTranslation = () => {
    if (this.customer_has_pocket_account && !this.customer_has_wealth_account) {
      this.label_subtitle_translation = 'card_1.description_only_pocket';
    }

    if (this.customer_has_wealth_account && !this.customer_has_pocket_account) {
      this.label_subtitle_translation = 'card_1.description_only_wealth';
    }
  }

  validateIfWealthBalanceIsEqualToZero = async () => {
    try {
      const allianz_account_reports = await this.search_allianz_account_report_query.execute(
        this.customer_id,
      );
      const allianz_balance = allianz_account_reports.reduce(
        (total, current) => total + current.final_balance_mxn!, 0,
      );
      this.wealth_balance_is_equal_to_zero = allianz_balance === 0.00;
      if (this.wealth_balance_is_equal_to_zero) {
        this.setActiveColorsWealthSection();
      }
    } catch (error) {
      if (error.message !== this.error_allianz_account_report_not_exits) {
        this.message_notifier.showErrorNotification(
          this.translate(`${this.i18n_namespace}.errors.load_wealth_balance`),
        );
      } else {
        this.want_to_delete = true;
      }
    }
  }

  validateIfCustomerHasContractedInvestmentProducts = async () => {
    try {
      const contracted_products = await this.get_contracted_products_query.execute();
      this.customer_has_pocket_account = contracted_products.has_sowos_pocket_contracted;
      this.customer_has_wealth_account = contracted_products.has_sowos_wealth_contracted;

      if (!contracted_products.has_any_product_contracted) {
        this.want_to_delete = true;
      }

      if (contracted_products.has_any_product_contracted) {
        await this.validateIfWealthBalanceIsEqualToZero();
        this.setLabelCriterionTranslation();
        this.setSubtitleTranslation();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_customer_contracted_products'));
    }
  }

  nextStep = () => {
    this.want_to_delete = true;
  }

  deleteAccount = async () => {
    this.is_loading = true;
    try {
      await this.cancel_account_command.execute({ password: this.inputs.password });
      this.show_user_profile_delete_account_dialog = true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.delete_account'));
    } finally {
      this.is_loading = false;
    }
  }

  showFlowWithdrawalTotalWealth = () => {
    this.flow_withdrawal_total_wealth_is_open = true;
  }

  initialize = async () => {
    this.is_loading = true;
    await this.validateIfCustomerHasContractedInvestmentProducts();
    this.initial_load_is_complete = true;
    this.is_loading = false;
  }
}
